import apiClient from '../../axios'

export default class SalesService {
  static async getClientSales(data) {
    try {
      const response = await apiClient.get('/admin/sales?accepted', { params: data })
      return response
    } catch (error) {
      console.error('Error fetching client sales:', error)
      throw error
    }
  }

  static async confirmClientSale(paymentId) {
    try {
      const response = await apiClient.patch(`/admin/sales/${paymentId}/confirm`)
      return response
    } catch (error) {
      console.error('Error confirming client sale:', error)
      throw error
    }
  }

  static async cancelClientSale(paymentId) {
    try {
      const response = await apiClient.patch(`/admin/sales/${paymentId}/cancel`)
      return response
    } catch (error) {
      console.error('Error canceling client sale:', error)
      throw error
    }
  }
}
